import React, { Component } from 'react';
import CollectionPage from '../../../components/collection_page'

class CollectionShapeshifter extends Component {
  render() {
    return (
      <>
        <CollectionPage collection="shapeshifter" />
      </>
    );
  }
}

export default CollectionShapeshifter;
