import React, { Component } from 'react';
import CollectionPage from '../../../components/collection_page'

class CollectionMoonchild extends Component {
  render() {
    return (
      <>
        <CollectionPage collection="moonchild" />
      </>
    );
  }
}

export default CollectionMoonchild;