import React, { Component } from 'react'
import { Container, Row } from 'styled-bootstrap-grid2'
import Container3Col from '../../../../components/container_3col'
import ProductListFilterCategory from '../../../../components/product_list_filter_category'

class CollectionBlessingShop extends Component {
  render() {
    return (
      <div>
        <Container3Col longpage top>
          <Container>
            <Row>
              <ProductListFilterCategory category="blessings" />
            </Row>
          </Container>
        </Container3Col>
      </div>
    );
  }
}

export default CollectionBlessingShop;
