import React, { Component } from 'react';
import CollectionPage from '../../../components/collection_page'
import Logo from '../../../images/cam_x_theo.svg';

class CollectionMoonchild extends Component {
  render() {
    return (
      <>
        <CollectionPage shoptitle="Camille Charriere x Theodora Warre." collection="camille-charriere-x-theo" logo={Logo} />
      </>
    );
  }
}

export default CollectionMoonchild;
