import React, { Component } from 'react';
import CollectionPagePrismic from '../../../components/collection_page_prismic'

class CollectionVictoriana extends Component {
  render() {
    return (
      <>
        <CollectionPagePrismic collection="victoriana" />
      </>
    );
  }
}

export default CollectionVictoriana;
