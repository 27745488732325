import React, { Component } from 'react';
import styled from 'styled-components';

const CartWrapper = styled.div`
  padding-top: 0px;
`

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 270px;

  @media (max-width: 768px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-left: 0px;
  }

  p {
    padding-bottom: 2rem;
    color: ${props => props.theme.brown4};
    padding-right: 4rem;
    &:last-child {
      padding-bottom: 0px;
    }
    a, i {
      text-decoration: none;
      font-style: italic;
      color: ${props => props.theme.brown4};
      transition: all 0.25s ease-in-out;
    }
    a:hover {
      color: ${props => props.theme.brown4};
    }
  }
`

class ContainerProduct extends Component {
  render() {
    return (
      <CartWrapper>
        <Container {...this.props}>
          {this.props.children}
        </Container>
      </CartWrapper>
    );
  }
}

export default ContainerProduct;
