import React, { Component } from 'react';
import CollectionPage from '../../../components/collection_page'

class CollectionBlessing extends Component {
  render() {
    return (
      <>
        <CollectionPage collection="blessing-collection" />
      </>
    );
  }
}

export default CollectionBlessing;