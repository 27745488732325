import React, { Component } from 'react'
import { Container, Row, Col } from 'styled-bootstrap-grid2'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import ContainerProduct from '../container_product'
import { Collections } from '../../collections'
import SEO from '../seo'
import GatsbyImage from 'gatsby-image';

const Logo = styled.img`
  max-width: 400px;
`
const Desktop = styled.div`
  display: block;
  img {
    height: 80vh;
    width: auto;
    max-width: none !important;
  }
  @media (max-width: 768px) {
    display: none;
  }
`
const Mobile = styled.div`
  display: none;
  img {
  }
  @media (max-width: 768px) {
    display: block;
  }
`

const ContainerCollection = styled.div`

  /* padding-top: 120px; */
  @media (max-width: 768px) {
    padding-top: 50px;
  }

  & > div > div {
    padding-left: 0px;
    padding-right: 0px;
  }

  .row1 {
    /* border: 1px solid brown; */
    img {
      border: 1px solid #f3f3f3;
    }
  }

  .row2 {
    /* border: 1px solid green; */
    padding-top: 120px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    img {
      max-width: 100%;
      height: auto;
    }
    .left-col {
      width: 35%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media (max-width: 768px) {
          width: 65%;
          margin-bottom: 120px;
      }
      img {
        margin-right: 90px;
        border: 1px solid #f3f3f3;
        @media (max-width: 768px) {
          margin-right: 0px;
        }
      }
    }
    .right-col {
      width: 65%;
      @media (max-width: 768px) {
          width: 100%;
          img {
            margin-left: 20px;
          }
      }
    }
  }

  .row3 {
    padding-top: 120px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    /* border: 1px solid blue; */
    img {
      max-width: 100%;
      height: auto;
    }
    .left-col {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      img {
        margin-left: 60px;
      }
      @media (max-width: 768px) {
          width: 100%;
          margin-bottom: 120px;
          img {
            margin-left: 40px;
            margin-right: 40px;
          }
      }
    }
    .right-col {
      width: 50%;
      img {
        margin-top: 300px;
        margin-left: 100px;
        border: 1px solid #f3f3f3;
      }
      @media (max-width: 768px) {
          width: 100%;
          img {
            margin-left: 20px;
            margin-top: 0px;
          }
      }
    }
  }

  .row4 {
    padding-top: 120px;
    padding-bottom: 120px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    img {
      max-width: 100%;
      height: auto;
    }
    .left-col {
      width: 75%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      img {
        margin-right: 60px;
      }
      @media (max-width: 768px) {
          width: 100%;
          margin-bottom: 120px;
          img {
            margin-right: 20px;
            margin-left: 20px;
          }
      }
    }
    .right-col {
      display: flex;
      align-items: center;
      width: 25%;
      @media (max-width: 768px) {
          width: 80%;
      }
      img {
        margin-left: 0px;
        border: 1px solid #f3f3f3;
      }
    }
  }
`

const Row1 = styled.div`
  display: flex;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
  .left {
    width: 40%;
    img {
      border: 1px solid #f3f3f3;
    }
    @media (max-width: 1200px) {
      width: 100%;
      order: 2;
      margin-top: 100px;
    }
  }
  .right {
    width: 50%;
    position: relative;
    @media (max-width: 1200px) {
      width: 100%;
      order: 1;
    }
    .intro {
      position: absolute;
      bottom: 0px;
      right: 0px;
      padding-left: 100px;
      padding-bottom: 100px;
      img {
        border: none;
      }
      @media (max-width: 1200px) {
        position: relative;
        padding-left: 20px;
        padding-bottom: 0px;
        padding-top: 100px;
        padding-right: 20px;
      }
      p {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 0px;
      }
      a {
        text-decoration: none;
        font-style: italic;
        font-family: 'minerva-modern', Garamond, Georgia, 'Times New Roman', Times, serif;
        color: ${props => props.theme.link_color};
      }
    }
  }
`

const CollectionDetails = styled.div`
  bottom: 0px;
  height: 100%;
  div {
    position: absolute;
    bottom: 120px;
    width: 100%;
    padding-left: 13%;
    padding-right: 10%;
    @media (max-width: 768px) {
      position: relative;
      margin-bottom: 3rem;
      bottom: auto;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  p {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 0px;
  }
  a {
    text-decoration: none;
    font-style: italic;
    font-family: 'minerva-modern', Garamond, Georgia, 'Times New Roman', Times, serif;
    color: ${props => props.theme.link_color};
  }
`
const ImgIntro = styled.img`
  max-width: 100%;
  max-height: 80vh;
  margin-left: auto;
  margin-right: auto;
`

class CollectionPage extends Component {

  render() {

    const collection = Collections.filter(c => c.name.toLowerCase() === this.props.collection)[0];
    const { title, body, intro_link, shop_url, images, name } = collection;
    const intro_image = require(`../../pages/collections/${name}/${collection.intro_image}`);

    const image1 = require(`../../pages/collections/${name}/${images[0].image}`);
    const image2 = require(`../../pages/collections/${name}/${images[1].image}`);
    const image3 = require(`../../pages/collections/${name}/${images[2].image}`);
    const image4 = require(`../../pages/collections/${name}/${images[3].image}`);
    const image5 = require(`../../pages/collections/${name}/${images[4].image}`);
    const image6 = require(`../../pages/collections/${name}/${images[5].image}`);

    const realTitle = this.props.shoptitle || title;

    return (
      <ContainerCollection>
        <SEO title={realTitle} keywords={[`Shop`, `Jewellery`,`Theodora`, `Warre`]} />
        <ContainerProduct>
          <Container fluid style={{ padding: '0px'}}>

            <div className="row1">

              <Row1>
                <div className="left">
                { intro_link ? (
                  <Link to={intro_link}><ImgIntro src={intro_image} /></Link>
                ) : (
                  <ImgIntro src={intro_image} />
                )}
                </div>
                <div className="right">

                    <div className="intro">
                      { this.props.logo &&
                        <Logo src={this.props.logo} />
                      }
                      { !this.props.logo &&
                        <h3>{realTitle}</h3>
                      }
                      <p>{body}</p>
                      <Link to={shop_url}>Shop the {realTitle} collection</Link>
                    </div>

                </div>
              </Row1>

            </div>

          </Container>
        </ContainerProduct>
      </ContainerCollection>
    );
  }
}

export default CollectionPage;
