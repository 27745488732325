import React, { Component } from 'react';
import CollectionPage from '../../../components/collection_page'

class CollectionFortune extends Component {
  render() {
    return (
      <>
        <CollectionPage collection="fortune-teller" />
      </>
    );
  }
}

export default CollectionFortune;